  <form>
    <div class="arranged">
      <div class="items">
        <mat-form-field>
          <mat-label>Program/Faculty Name</mat-label>
          <input
                 matInput
                 [formControl]="programControl"
                 [matAutocomplete]="auto">
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let program of filteredPrograms | async" [value]="program">
              {{program}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>

      <div class="items">
        <mat-form-field>
          <mat-label>Number of courses</mat-label>
          <mat-select class="inputItem" matInput [formControl]="instancesControl" [errorStateMatcher]="matcher">
            <mat-option *ngFor="let number of numbers" value="{{number}}">{{number}}</mat-option>
          </mat-select>
<!--          <mat-error *ngIf="instancesControl.hasError('min')">You must select a number</mat-error>-->
        </mat-form-field>
      </div>

      <div class="items">
        <mat-form-field>
          <mat-label>Credit Hours/Course</mat-label>
          <mat-select matInput [formControl]="creditsControl" [errorStateMatcher]="matcher" [(value)]="credits">
            <mat-option *ngFor="let number of numbers" [value]="number">{{number}}</mat-option>
          </mat-select>
          <!--          <mat-error *ngIf="instancesControl.hasError('min')">You must select a number</mat-error>-->
        </mat-form-field>
      </div>


      <div class="items">
        <button mat-mini-fab (click)="removeProgram.emit()"><mat-icon>close</mat-icon></button>
      </div>
    </div>
  </form>


